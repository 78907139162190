<template>
  <div class="orderList">
    <nav-bar :isNavWx="true" :title="'我的訂單'" :isFixed="true" :bgc="'background:#fff'">
      <template #right>
        <div class="seach">
          <input v-model="formPage.keyword" type="text" :placeholder="$t('搜索我的訂單')" />
          <img @click="handleSeachOrder" src="@/assets/image/order/seach.png" alt="" />
        </div>
      </template>
    </nav-bar>
    <div class="tab-list">
      <van-tabs :swipe-threshold="4" v-model:active="active" color="#90D12E" title-active-color="#90D12E" title-inactive-color="#666666"
        @click-tab="handleSeachGoods">
        <van-tab title-style="font-size:14px;" :title="$t('所有訂單')" name="-1"></van-tab>
        <van-tab title-style="font-size:14px;" :title="$t('待付款')" name="0"></van-tab>
        <van-tab title-style="font-size:14px;" :title="$t('待發貨')" name="2"></van-tab>
        <van-tab title-style="font-size:14px;" :title="$t('待收貨')" name="3"></van-tab>
        <van-tab title-style="font-size:14px;" :title="$t('已完成')" name="4"></van-tab>
      </van-tabs>
    </div>

    <div class="goods-list" v-if="orderList.length > 0">
      <div class="goods-content">
        <div class="goods-item" v-for="item in orderList" :key="item.orderNo" @click="gotoOrderDetail(item)">
          <div class="order">
            <div>{{ item.orderNo }}</div>
            <div>{{ item.createTime }}</div>
          </div>
          <div class="product" v-for="goods in item.goods.slice(0,orderIndex)" :key="goods.searchProductId">
            <div class="goods-img">
              <img :src="getProductImageUrl(goods.picturePath,400)" @error="imgOnError($event)" alt="" />
              <div class="tags" v-if="goods.tag">{{ goods.tag }}</div>
            </div>
            <div class="product-info">
              <div class="name">
                {{ goods.productName }}
              </div>
              <div class="goods-tag" v-if="goods.fulltag">{{ goods.fulltag }}</div>
              <div class="uint">{{ goods.standard }}</div>
              <div class="price">
                <div>{{ formatNumber(goods.price) }}</div>
                <div class="count">{{ $t("數量") }}{{ goods.amount }}</div>
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="viewall" v-if="item.goods.length > 3 && isAll" @click.stop="getViewallGoods(item)">
              {{ $t("查看所有") }} {{ item.goods?.length }}{{ $t("個商品") }}
              <img src="@/assets/image/order/direction.png" alt="" />
            </div>

            <div class="order-status">
              <div class="checkStatus" v-if="item.checkStatus === 0 && item.payType === 53">{{ $t('未付支票') }}</div>
              <div v-else></div>
              <div class="order-price price">
                {{ $t("訂單總計") }} {{ formatNumber(item.totalPrice) }}
              </div>
            </div>
            <div class="price">{{ $t(orderStatus(item.status,item)) }}</div>
          </div>
          <div class="order-btn" v-if="item.status == 0" @click="gotoOrderDetail(item)">
            {{ $t("去支付") }}
          </div>
          <div class="order-btn" @click.stop="getViewLogistics(item)" v-if="item.status == 3">
            {{ $t("查看物流") }}
          </div>
        </div>
      </div>
      <van-loading v-if="isMore" size="13px" class="loading" vertical>{{ $t("加载中") }}...</van-loading>
      <div v-else class="more">{{ $t("沒有更多啦") }}~</div>
    </div>
    <div v-else class="orderempty">
      <img src="@/assets/image/order/orderempty.png" alt="" />
      <div class="noOrder">{{ $t("暫無訂單") }}</div>
    </div>
  </div>
</template>

<script>
import { getOrderList } from "@/api/user.js"
import mixins from "@/utils/mixins.js"
import defaultImage from "@/assets/image/default.png"
export default {
  name: 'orderList',
  mixins: [mixins],
  data () {
    return {
      active: "-1",
      formPage: { status: -1, keyword: "", pageIndex: 1, pageSize: 10 },
      orderList: [],
      loading: false,
      finished: false,
      isMore: false,
      count: "",
      isAll: true,
      orderIndex: 3
    }
  },
  created () {
    if (this.$route.query.status || this.$route.query.status == 0) {
      this.formPage.status = Number(this.$route.query.status)
      this.active = this.$route.query.status
    }
    this.getList()
  },
  mounted () {
    window.addEventListener("scroll", this.handleScroll)
  },
  methods: {
    imgOnError (e) {
      e.target.src = defaultImage
    },
    getViewallGoods (item) {
      this.isAll = false
      this.orderIndex = undefined
    },
    getViewLogistics (item) {
      this.$router.push(`/order/logistics/${item.nuList.join(",")}`)
    },
    gotoOrderDetail (item) {
      this.$router.push(`/user/orderDetail/${item.orderNo}`)
    },
    handleSeachOrder () {
      this.formPage.pageIndex = 1
      this.getList()
      // this.formPage.keyword = "";
    },
    async handleScroll (event) {
      const windowHeight = window.innerHeight
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      )
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop
      const hasVerticalScrollbar =
        document.documentElement.scrollHeight >
        document.documentElement.clientHeight

      if (hasVerticalScrollbar && scrollTop + windowHeight >= documentHeight) {
        this.isMore = true
        this.formPage.pageIndex += 1
        await this.getList()
      }
    },
    handleSeachGoods (item) {
      this.formPage.pageIndex = 1
      this.formPage.status = Number(item.name)
      this.getList()
      window.scrollTo(0, 0)
    },
    async getList () {
      const res = await getOrderList(this.formPage)
      if (res.resultID === 1200) {
        if (this.formPage.pageIndex > 1) {
          if (res.data.list.length === 0) {
            this.isMore = false
          } else {
            this.orderList = [...this.orderList, ...res.data.list]
          }
        } else {
          this.orderList = res.data.list
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.orderList {
  .goods-list {
    padding-top: 100px;

    .order-btn {
      width: 100%;
      padding: 6px;
      background: #90d12e;
      border-radius: 8px 8px 8px 8px;
      margin-top: 4px;
      text-align: center;
      color: #fff;
      font-size: 15px;
    }

    .bottom {
      margin-top: 16px;

      .price {
        display: flex;
        justify-content: flex-end;
        color: #1a1a1a;
      }

      .order-status {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .checkStatus {
          background: #FCE7E5;
          padding: 2px 4px;
          box-sizing: border-box;
          border-radius: 4px 4px 4px 4px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 12px;
          color: #E84935;
        }

        .order-price {

          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 15px;
          color: #f82207;
        }
      }

      .viewall {
        display: flex;
        font-weight: 400;
        font-size: 15px;
        color: #999999;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
          margin-left: 4px;
        }
      }
    }

    .goods-item {
      margin-top: 12px;
      background-color: #fff;
      padding: 16px;
      border-radius: 16px;
    }

    .goods-content {
      .order {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        color: #999999;
        align-items: center;
      }

      .product {
        display: flex;
        margin-top: 16px;

        .goods-img {
          position: relative;
          margin-right: 16px;
          font-size: 0;

          img {
            width: 120px;
            height: 120px;
          }

          .tags {
            width: 32px;
            height: 20px;
            text-align: center;
            background: #E84935;
            border-radius: 1px 8px 1px 10px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 11px;
            color: #FFFFFF;
            line-height: 20px;
            position: absolute;
            top: 0;
            right: 0;
          }
        }



        .product-info {
          position: relative;
          width: 100%;

          .name {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
          }

          .goods-tag {
            display: inline-block;
            padding: 0 2px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 11px;
            color: #E84935;
            line-height: 16px;
            border-radius: 2px 2px 2px 2px;
            border: 1px solid rgba(232, 47, 47, 0.5);
          }

          .uint {
            font-size: 12px;
            color: #999999;
            margin-top: 4px;
          }

          .price {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            bottom: 0;
            position: absolute;
            font-weight: 800;
            font-size: 13px;
            color: #1a1a1a;

            .count {
              font-size: 12px;
              color: #1a1a1a;
              font-weight: normal;
            }
          }
        }



        .price {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  .tab-list {
    margin-top: 44px;
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 10;
  }

  .seach {
    position: relative;

    img {
      position: absolute;
      width: 16px;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    input {
      width: 233px;
      height: 36px;
      background: #ffffff;
      border-radius: 12px 12px 12px 12px;
      border: 1px solid #90d12e;
      padding-left: 10px;
      font-size: 14px;
      color: #bdbdbd;
    }
  }

  .more {
    text-align: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #999999;
    line-height: 20px;
    padding-bottom: 30px;
  }

  .loading {
    text-align: center;
    padding-bottom: 30px;
  }

  .orderempty {
    padding-top: 200px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    color: #1a1a1a;

    img {
      width: 140px;
      height: 140px;
      object-fit: contain;
    }

    .noOrder {
      width: 140px;
    }
  }
}
</style>
